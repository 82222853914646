import React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import About from "../components/about"

const AboutPage = () => (
  <Layout>
    <About />
  </Layout>
)

export default AboutPage

export const Head = () => <Seo title="About | Universal Game Protection" />
