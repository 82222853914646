import React from "react"

import { ytpLargePlayBtnBg, buttonThing } from "./styles.module.less"

const PlayButton = props => (
  <svg
    style={{ transform: "translate(11px, 15px)" }}
    height={48}
    viewBox="0 0 68 48"
    width={68}
    {...props}
  >
    <path
      className={`${ytpLargePlayBtnBg} ${buttonThing}`}
      d="M.66 37.62s.66 4.7 2.7 6.77c2.58 2.71 5.98 2.63 7.49 2.91 5.43.52 23.1.68 23.12.68 0 0 14.29-.02 23.81-.71 1.32-.15 4.22-.17 6.81-2.89 2.03-2.07 2.7-6.77 2.7-6.77s.67-5.52.67-11.04V21.4c0-5.52-.67-11.04-.67-11.04s-.66-4.7-2.7-6.77C62.03.86 59.13.84 57.8.69 48.28 0 34 0 34 0c-.03 0-14.31 0-23.82.69-1.33.15-4.23.17-6.82 2.89-2.04 2.07-2.7 6.77-2.7 6.77S.11 14.85 0 19.8v8.36c.1 4.94.66 9.45.66 9.45z"
      fill="#1f1f1e"
    />
    <path d="M26.96 13.67l18.37 9.62-18.37 9.55V13.67z" fill="#fff" />
    <path d="M45.02 23.46l.3-.18-18.36-9.61 16.36 10.67 1.7-.88z" fill="#ccc" />
  </svg>
)

export default PlayButton
