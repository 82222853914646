// extracted by mini-css-extract-plugin
export var buttonThing = "styles-module--buttonThing--3X7rc";
export var container = "styles-module--container--S9yj4";
export var endedMode = "styles-module--ended-mode--HXUiR";
export var html5VideoPlayer = "styles-module--html5VideoPlayer--7GEWW";
export var playingMode = "styles-module--playing-mode--maIYV";
export var unstartedMode = "styles-module--unstarted-mode--t5+4f";
export var ytpButton = "styles-module--ytpButton--1CPdE";
export var ytpCuedThumbnailOverlay = "styles-module--ytp-cued-thumbnail-overlay--MLeJn";
export var ytpLargePlayBtnBg = "styles-module--ytpLargePlayBtnBg--ijPwh";
export var ytpLargePlayButton = "styles-module--ytp-large-play-button--dkxrI";
export var ytpLargePlayButtonBg = "styles-module--ytp-large-play-button-bg--Xed2p";
export var ytpSmallMode = "styles-module--ytpSmallMode--O+FAy";
export var ytpThumbnailOverlay = "styles-module--ytpThumbnailOverlay--F2AUE";
export var ytpTouchDevice = "styles-module--ytp-touch-device--UjrLN";