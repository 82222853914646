import React from "react"
// import { window } from "global/window"

import YouTubeLink from "../youtubeLink"

import salPicture from "../../images/g2ea13.jpeg"
import deePicture from "../../images/bioImageTwo.jpg"
import firstVideo from "../../images/1tv.jpg"
import secondVideo from "../../images/2tv.jpg"
import thirdVideo from "../../images/3tv.jpg"
import fourthVideo from "../../images/4tv.jpg"
import fifthVideo from "../../images/5tv.jpg"
import { container, copy, videos } from "./styles.module.less"

const About = () => {
  return (
    <React.Fragment>
      <div className={container}>
        <div className={copy}>
          <h1>About Us</h1>
          <p>
            We believe in a proactive approach to game protection. All of table
            games and surveillance staffs should be taught why certain policies
            and procedures are in place and what can happen if they are not
            followed, which is surprisingly rare in the casino industry. Then
            they should be held accountable for any infractions. Although we
            recommend that our clients take this proactive approach, we also
            offer services when reactive steps are necessary in response to
            declining house hold (win) percentages and/or suspect play.
          </p>
          <p>
            Some competitors tried to tell our clients that we sell fear. We’d
            like to thank them for their concern and correct this
            misinformation. In reality, we sell knowledge. The most eloquent
            response we could find is Shakespeare’s Henry VIII, “Things done
            well, And with a care, exempt themselves from fear: Things done
            without example, in their issue Are to be fear’d.”
          </p>
          <p>
            We’ve got it covered! With over 26 years of casino industry
            experience, UGPD president, Sal Piacente, does Game Protection well
            and he cares about the quality of the work. His training includes
            examples of scams, theft, and advantage plays, not only on actual
            video surveillance footage, but also through live demonstrations, to
            give the trainees a thorough understanding of the material presented
            in a way that is relatable to them because he has held their job
            positions. Even with his extensive knowledge, Piacente is the
            eternal student, so that UGPD can show its clients the most current
            threats to casino profitability.
          </p>

          <h2>Our Team</h2>

          <img src={salPicture} alt="Sal" style={{ marginTop: `15px` }} />
          <h3>Sal Piacente</h3>

          <p>
            Sal Piacente is a native of Brooklyn, New York, and the ultimate
            student of the game. Piacente's interest in con games and scams
            began on the streets at a young age when his father taught him to
            protect himself from 3 Card Monte hustlers. He started his casino
            career as a blackjack dealer in Atlantic City after an honorable
            discharge from the United States Marine Corps. Sal then worked his
            way up from dealer to shift manager to game protection specialist, a
            position created for him by an international casino corporation. Sal
            Piacente is also an instructor for the University of Nevada, Reno's
            Gaming Management Certification Program. Piacente has parlayed his
            experience, skills, passion, and knowledge of the various ways to
            cheat all casino games into a successful consulting business. Over
            the past 25 years, Sal has trained and/or consulted for dozens of
            casinos and regulatory bodies on four continents (and counting), and
            has demonstrated his amazing manual dexterity on a number of
            television shows.
          </p>

          <p>
            While studying card counting, Sal came across memory techniques
            (mnemonics) used by advantage players and cheats to increase their
            odds of winning at blackjack. Not only can Piacente perform amazing
            casino-related mnemonic stunts, but he also teaches others to
            improve their information retention for everyday life. His memory
            book collection spans centuries, with his oldest book dating back to
            the Italian Renaissance. Though Sal “the Hitman” Piacente is a
            consummate authority on casino scams and memory, his thirst for
            knowledge is never satisfied.
          </p>

          <img src={deePicture} alt="Sal" style={{ marginTop: `15px` }} />
          <h3>Dee Piacente</h3>

          <p>
            Dee Piacente is the office manager and Secret Shopper services
            coordinator for UniverSal Game Protection Development, Inc. She
            began her career as a dealer in Atlantic City and was promoted to
            floorperson and pit boss positions for casinos in Reno and Las
            Vegas.
          </p>
          <p>
            In 1994, while still in Atlantic City, she had the privilege of
            watching Sal perform live demonstrations at a dealing school where
            she was studying pai gow poker. Since then, Sal and Dee have been an
            inseparable force in their professional and personal lives, recently
            celebrating 11 years of marriage and the 5th anniversary of
            UniverSal Game Protection Development, Inc.
          </p>
        </div>

        <div className={videos}>
          <h3>Television Appearances</h3>
          <YouTubeLink
            label={"Sal discusses dealer aprons and chip cameras"}
            videoLink={"https://www.youtube.com/watch?v=JaZRUs_eIlc"}
            background={thirdVideo}
            videoCaption={""}
          />
          <YouTubeLink
            label={"Sal on the Travel Channel"}
            videoLink={"https://www.youtube.com/watch?v=tetsp22C9xM"}
            background={fourthVideo}
            videoCaption={""}
          />
          <YouTubeLink
            label={"Sal at the World Gaming Convention"}
            videoLink={"https://www.youtube.com/watch?v=H1b4pT9CY3M"}
            background={secondVideo}
            videoCaption={""}
          />
          <YouTubeLink
            label={"Sal on the news"}
            videoLink={"https://www.youtube.com/watch?v=JNn7skBpTqA"}
            background={fifthVideo}
            videoCaption={""}
          />
          <YouTubeLink
            label={"Sal on Good Morning America"}
            videoLink={"https://www.youtube.com/watch?v=BpytJthAZjo"}
            background={firstVideo}
            videoCaption={""}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default About
