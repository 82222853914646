import React from "react"
import PlayButton from "./playButton"

import {
  container,
  ytpButton,
  ytpSmallMode,
  ytpThumbnailOverlay,
} from "./styles.module.less"

const YouTubeLink = ({ label, videoLink, background, videoCaption }) => {
  return (
    <div className={container}>
      <div
        className={`${ytpThumbnailOverlay}`}
        data-layer="4"
        style={{
          backgroundImage: `url(${background})`,
          width: 300,
          height: 170,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button className={`${ytpSmallMode} ${ytpButton}`} aria-label={label}>
          <a
            href={videoLink}
            target="_blank"
            rel="noopener noreferrer"
            title={label}
          >
            <PlayButton />
          </a>
        </button>
      </div>
      <p>
        <em>{videoCaption}</em>
      </p>
    </div>
  )
}

export default YouTubeLink
